import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store  from './store';
import axios from 'axios';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars , faSearch , faMinus , faPlus} from '@fortawesome/free-solid-svg-icons'
import { faFacebookF } from '@fortawesome/fontawesome-free-brands';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import 'remixicon/fonts/remixicon.css'
import './assets/js/scripts.js'
import  i18n  from '@/plugins/i18n';
import FlagIcon from 'vue-flag-icon';
import './registerServiceWorker'


Vue.use(FlagIcon);

library.add(faBars , faSearch, faFacebookF , faMinus , faPlus)
Vue.component('font-awesome-icon', FontAwesomeIcon)

axios.defaults.withCredentials = true
axios.defaults.baseURL = 'https://xpanel.tikibistro.ro/api/';

Vue.config.productionTip = false

new Vue({
  i18n,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
