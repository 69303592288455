import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/news/:id',
    name: 'news',
    component: () => import('../views/news_article.vue')
  },
  {
    path: '/termeni_si_conditii',
    name: 'termeni_si_conditii',
    component: () => import('../views/termeni_conditii.vue')
  },
  {
    path: '/faq_suport',
    name: 'faq_suport',
    component: () => import('../views/faq_suport.vue')
  },
  {
    path: '/contul-meu',
    name: 'Account',
    component: () => import('../views/Contul_meu.vue')
  },
  {
    path: '/meniu',
    name: 'Meniu',
    component: () => import('../views/meniu_produse.vue')
  },
  {
    path: '/bucatarie',
    name: 'Bucatarie',
    component: () => import('../views/bucatarie.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('../views/news.vue')
  },
  {
    path: '/paid',
    name: 'Paid',
    component: () => import('../views/paid.vue')
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import('../views/error.vue')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/Search.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/politica_de_confidentialitate',
    name: 'Politica de confidentialitate',
    component: () => import('../views/politica_de_confidentialitate.vue')
  },
  {
    path: '/404',
    name: '404',
    component: () => import('../views/404.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
