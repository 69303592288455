import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    'ro': {
        comanda_acum: 'Comandă acum',
        meniu: 'Meniu',
        my_profile : 'Profilul meu',
        news: 'Noutăți',
        about_us: 'Despre noi',
        contact: 'Contact',
        settings: 'Setări',
        my_orders: 'Comenzile mele',
        term_cond: 'Termeni & Condiții',
        priv_poli: 'Politica de confidențialitate',
        faq_support: 'FAQ & Suport',
        shopping_bag : 'Coș de cumpărături',
        products : 'produse',
        total: 'Total',
        finalizeaza_comanda: 'Finalizează comanda',
        continua_cumparaturile: 'Continuă Cumpărăturile',
        nume: 'Nume',
        prenume: 'Prenume',
        telefon: 'Telefon comenzi',
        telefon_rezervari : "Telefon rezervări",
        salveaza: 'Salvează',
        continua_facebook: 'Continuă cu Facebook',
        continua_gmail:'Continuă cu Gmail',
        sign_up: 'Înregistrează-te',
        sign_upp : 'Înregistrează-te',
        ai_cont_deja : 'Nu ai cont?',
        login: 'Autentificare',
        inscriere_acceptati: 'Prin înscriere acceptați',
        si_datele : 'și datele',
        create_account: 'Creează-ți cont',
        password: 'Password',
        welcome_back: 'Bine ai revenit!',
        forgot_pass:'Ai uitat parola?',
        forgot_pass_text: 'Introduceți adresa de e-mail și vă vom trimite instrucțiuni pentru a vă reseta parola.',
        recover_pass: 'Recuperează parola',
        check_email: 'Verifică email-ul',
        check_email_text: 'Am trimis instrucțiuni despre cum puteți să resetați parola (Verificați și spamul).',
        go_to_email: 'Accesați Email-ul',
        orders: 'Comenzi',
        order: 'Comanda',
        pe_drum: "Pe drum.",
        completed: 'Completă',
        canceled: 'Anulată',
        language: 'Limba',
        location: 'Locație',
        data_access: 'Acces la date',
        data_access_text: "Tiki Bistro folosește următoarele date pentru a personaliza o adaptare în timp real.",
        location_access: 'Acces la locație',
        recive_notif: 'Primiți notificări',
        recive_newsletters: 'Abonează-te la Newsletter',
        recive_special_offers: 'Primiți oferte speciale',
        livrari_si_take_away: 'Livrări și take-away',
        fiecare_zi: 'În fiecare zi',
        vezi_tot_meniul:'Vezi tot meniul',
        special_offers: 'Oferte speciale',
        cuisines: 'Mâncăruri',
        best_sellers: 'Cele mai vândute',
        bauturi_racoritoare: 'Băuturi răcoritoare',
        bauturi_alcoolice: 'Băuturi alcoolice',
        restaurant_exotic:'Tiki Bistro, un mic restaurant exotic',
        restaurant_exotic_desc: "Am așezat în talerele imaginației lucrurile care ne plac cel mai tare:să mâncăm biiine de tot, să medităm într-o oază exotică și mereu plină de prieteni, să putem lucra, dacă avem laptopul cu noi, dar și să rămânem să petrecem, dacă distracția se prelungește până noaptea târziu.",
        story: "Lasă-mă să-ți spun o poveste...",
        story_text: "De la începuturile noastre modeste din 2005, cu puțin spațiu în eleganta localitate Yorkville din Iasi, ​​dezvoltarea TikiBistro a fost însuflețită cu energia de a găti și a servi mâncăruri delicioase, exotice, selectate cu grijă din întreaga lume. Spre deosebire de alte bistrouri, TikiBistro a fost creat cu așteptarea explicită de a apărea ca altceva. Ne dăm seama că numeroase persoane adoră mâncarea exotică, totuși un număr mare dintre aceștia urăsc sau nu sunt conștienți de mixurile neobisnuite care fac ca gustul alimentelor indiene să fie atât de grozav. Meniul nostru evidențiază lucruri care utilizează sunetul și aromele parfumate, cu toate acestea uită de umplutura de ghee, unt, ulei și cremă copleșitoare. ",
        about_text: "Ne dăm seama că numeroase persoane adoră mâncarea exotică, totuși un număr mare dintre aceștia urăsc sau nu sunt conștienți de mixurile neobisnuite care fac ca gustul alimentelor indiene să fie atât de grozav. Meniul nostru evidențiază lucruri care utilizează sunetul și aromele parfumate, cu toate acestea uită de umplutura de ghee, unt, ulei și cremă copleșitoare. ",
        contact_us: "Contactează-ne",
        contact_us_desc: "Ai întrebări, nelămuriri sau vrei să rezervi o masă? Nu ezita să ne contactezi, fie folosind formularul alăturat, fie folosind datele de mai jos. :)",
        send: 'Trimite',
        vezi_meniul: 'Vezi meniul complet',
        vezi_toate: 'Vezi toate',
        adauga_in_cos: 'Adaugă în coș',
        produse_in_cos: "produse în coș",
        adauga_ingrediente:"Adaugă ingrediente extra",
        priv_poli_text: "Aici puteți pune oricare dintre textele pe care doriți să fie afișate aici. Poate fi orice text care credeți că ar fi potrivit. Puteți introduce politica de confidențialitate deja existentă sau puteți crea una nouă și o puteți pune și aici. Depinde în totalitate de dvs. și puteți utiliza orice text pe care credeți că ar fi potrivit pentru ecranul acestei aplicații.",
        one_product : "produs",
        my_addresses : "Adresele mele",
        my_cards: "Carduri",
        my_favorites: "Favorite",
        log_out: "Ieși din cont",
        items: "produse",
        delivery_address: "Adresa de livrare",
        where_to_deliver: "Unde livrăm?",
        payment_method: "Metoda de plată",
        select_payment_method:"Selectează metoda de plată",
        phone_number : "Numărul de telefon",
        your_phone_number:"Numărul tau de telefon?",
        delivery:"Livrare",
        confirm_order:"Confirmă comanda",
        address_details:"Detaliile adresei",
        complete:"Adaugă adresă",
        complete_phone:"Adauga telefon",
        enter_your_phone_number:"Introduceți numărul de telefon",
        eroare_login : "Datele de conectare sunt incorecte. Vă rugăm să verificați numele de utilizator și parola.",
        or: "sau",
        order_sent: "Comandă confirmată!",
        mesaj_timp_livrare1: "Am primit comanda dvs. În aproximativ ",
        mesaj_timp_livrare2: "minute ar trebui să fie la ușa ta.",
        mesaj_timp_livrare4: "Comanda dvs. a fost trimisă cu succes. Vă mulțumim!",
        go_it: "Urmărește",
        plata_la_preluarea_comenzii : "Plată cash la livrare",
        plata_cu_cardul : "Plată cu cardul sau ",
        nume_existent: "Adresa de email este deja folosită.",
        adrese_neadaugate: "Momentan nu ați adăugat nicio adresă.",
        adrese : "adrese",
        adauga_adresa_noua: "Adaugă o adresă noua",
        save_address : "Salvează adresa",
        edit_address: "Editează adresa",
        delete_address: "Șterge adresa",
        comanda_nepreluata: "Comandă nepreluată",
        la_bucatarie: "Comanda este la bucătărie",
        add_new_card: "Adaugă card nou",
        no_favorites: "Niciun produs la favorite",
        fara_produs_favorit: "Se pare că nu ați adăugat niciun produs în lista de favorite",
        selecteaza_adresa: "Selectează adresa",
        produs_adaugat_cos: "Produsul a fost adăugat în coș cu succes!",
        produs_adaugat_favorite: "Produsul a fost adăugat la lista de favorite!",
        produs_sters_favorite: "Produsul a fost scos din lista de favorite!",
        search_food: "Caută produse..",
        search_result: "Rezultatul cautării",
        best_sellers_result: "Vrei să încerci produsele cele mai vândute?",
        autentificare_succes: "Te-ai autentificat cu succes!",
        date_editate: "Datele contului au fost editate cu succes!",
        eroare_editare : "Toate câmpurile sunt obligatorii!",
        text_logout: "Te-ai deconectat din cont!",
        livrare_mai_mica_1 : "Îți mai trebuie ",
        livrare_mai_mica_2 : "lei pentru a avea livrarea gratuită!",
        livrare_minima : "lei pentru a atinge comanda minimă",
        livrare_gratuita: "Felicitări, ai livrare gratuită!",
        vezi_bucataria: "Vezi bucătăria",
        introdu_codul: "Introduceți codul din 4 cifre care v-a fost trimis la ",
        introdu_telefonul: "Introduceți numărul de telefon",
        next: "Următorul",
        cod_neprimit: "Nu am primit codul",
        apply_voucher : "Adaugă",
        voucher_status_expired : "Voucherul nu este valid",
        voucher_status_valid : "Voucherul a fost aplicat cu succes",
        comanda_platita : "Status comandă",
        comanda_refuzata : "Comanda a fost refuzată",
        introduceti_adresa_noua : "Doar stradă și număr",
        detalii_adresa: "Detalii: bloc, scară, ap, interfon, etc",
        cod_retrimis: "Codul a fost retrimis cu succes!",
        cod_netrimis:"Eroare la trimiterea codului.",
        adresa_default: "Aceasta adresă nu se poate modifica. De aici puteți ridica comenzile pentru livrare de la sediu.",
        no_orders: "Nicio comandă",
        no_orders_desc: "Incercați unul dintre podusele noastre și plasați prima comandă!",
        contact_trimis: "Mesajul a fost trimis cu succes!",
        enter_email_addr: "Introduceți adresa de email",
        ridicare_personala : "Ridicare personală",
        validare_telefon : "Validează telefonul",
        politica_de_confidentialitate: "Declar că sunt de acord cu colectarea datelor mele cu caracter personal de către Tiki Bistro în scopul utilizării acestora în procesul de comunicare",
        nume_contact : "Nume",
        mesaj_contact : "Mesaj",
        on : "la",
        introduceti_adresa_editata: "Introduceți adresă",
        mesaj_timp_livrare3: "Comanda dumneavoastră a fost plasată cu succes. Vă așteptăm la locația noastră pentru ridicarea comenzii!",
        alerta_telefon_null : "Numarul de telefon a fost adaugat cu succes!",
        verifica_phone : "Verifică numărul",
        descriere_comanda : 'Informații suplimentare (ex: fără sos)',
        comanda_cash : "<i class='ri-money-dollar-circle-line'></i> Plată cash.",
        comanda_card  : "<i class='ri-bank-card-fill'></i> Plată card.",
        livrare_ms : 'Livrare',
        tacamuri : 'Doresc tacâmuri',
        change_pass_text : 'Schimbă parola',
        change_pass_text2 : 'Adaugă o parolă nouă',
        parola_noua : 'Parolă nouă',
        parola_noua1 : 'Confirmare parolă nouă',
        reset_pass_btn : 'Resetează parola',
        nici_un_produs : 'Căutarea nu a returnat nici un produs. E posibil să vă placă produsele de mai jos.',
        sterge_voucher: 'Șterge',
        datele_mele : 'Datele mele',
        eroare_tranz : 'A apărut o eroare! <br/> <br/> Vă rugăm contactați-ne la numărul de telefon +40 (747) 247 876 pentru mai multe informații.  <br/><br/> Vă mulțumim!<br/><br/>',
        voucher_status_produse_reduse : "*Reducerea se aplică produselor care nu sunt în promoție.",
        livrare_generala : "Introduceți adresa pentru a putea calcula taxa de livrare.",
        alege_optiuni : 'Alege opțiunea care ți se potrivește',
        nota_produs_individual : 'Informații suplimentare (ex: fara ceapa)',
        adresa_adaugata : 'Adresa a fost adăugată cu succes',
        info_adr : '*Completează în câmpul de mai sus strada și <strong>numărul</strong> adresei pe care vrei să o adaugi.',
    },
    'en': {
        info_adr : 'Please insert above the name and <strong>number</strong> of street',
        adresa_adaugata : 'The address has been added',
        nota_produs_individual : 'Extra informations (ex: without onion)',
        alege_optiuni : 'Choose your option',
        livrare_generala : "Enter the address for calculate the delivery fee",
        voucher_status_produse_reduse : "*The voucher does not apply to already discounted products.",
        eroare_tranz : 'An error occured!<br/>  Please contact us at number +40 (747) 247 876 for more informatii.<br/>  Thank you!<br/><br/>',
        datele_mele : 'My data',
        sterge_voucher: 'Delete',
        nici_un_produs : '0 items found. You may like following dishes.',
        reset_pass_btn : 'Reset password',
        parola_noua : 'New password',
        parola_noua1 : 'Confirm new password',
        change_pass_text : 'Change password',
        change_pass_text2 : 'Add new password',
        tacamuri : 'I want cutlery',
        livrare_ms : 'Delivery',
        comanda_cash : "<i class='ri-money-dollar-circle-line'></i> Order paid with cash.",
        comanda_card  : "<i class='ri-bank-card-fill'></i> Order paid by credit card.",
        descriere_comanda : 'More information (ex: without souce)',
        mesaj_timp_livrare4: "Your order was sent. Thank you!",
        mesaj_contact : "Message",
        nume_contact : "Name",
        comanda_acum: 'Order now',
        meniu: 'Menu',
        my_profile : 'My profile',
        news: 'News',
        about_us: 'About us',
        contact: 'Contact',
        settings: 'Settings',
        my_orders: 'My Orders',
        term_cond:'Terms & Conditions',
        priv_poli: 'Privacy Policy',
        faq_support: 'FAQ & Support',
        shopping_bag : 'Shopping bag',
        products: 'products',
        total: 'Total',
        finalizeaza_comanda: 'Complete the order',
        continua_cumparaturile: 'Continue Shopping',
        nume: 'Last name',
        prenume: 'First name',
        telefon: 'Phone orders',
        telefon_rezervari : "Phone reservations",
        salveaza: 'Save',
        continua_facebook: 'Continue with Facebook',
        continua_gmail:'Continue with Gmail',
        sign_up: 'Sign up',
        sign_upp : 'Sign up',
        ai_cont_deja : 'You don`t have an account?',
        login: 'Log in',
        inscriere_acceptati: 'By signing up you accept the',
        si_datele : 'and the data',
        create_account: 'Create your account',
        password: 'Parola',
        welcome_back: 'Welcome back!',
        forgot_pass:'Forgot password?',
        forgot_pass_text: "Enter your email and we'll send you instructions to reset your password.",
        recover_pass: 'Recover password',
        check_email: 'Check your email',
        check_email_text: "We've sent instructions on how to reset The password (also check the Spam).",
        go_to_email: 'Go to Email',
        orders: 'Orders',
        order: 'Order',
        pe_drum: "On it’s way",
        completed: 'Completed',
        canceled: 'Canceled',
        language: 'Language',
        location: 'Location',
        data_access: 'Data Access',
        data_access_text: "Tiki Bistro uses the following data to personalize an adapt in real-time.",
        location_access: 'Location Access',
        recive_notif: 'Receive notifications',
        recive_newsletters: 'Receive newsletters',
        recive_special_offers: 'Receive special offers',
        livrari_si_take_away: 'Deliveries and take-away',
        fiecare_zi: 'Every day',
        vezi_tot_meniul:'See the full menu',
        special_offers: 'Special offers',
        cuisines: 'Cuisines',
        best_sellers:'Best Sellers',
        bauturi_racoritoare: 'Refreshments drinks',
        bauturi_alcoolice: 'Alcohol drinks',
        restaurant_exotic:'Tiki Bistro, an exotic little restaurant',
        restaurant_exotic_desc: "We put in the plates of the imagination the things we like best: to eat well, to meditate in an exotic oasis and always full of friends, to be able to work if we have the laptop with us, but also to stay to party if the fun lasts until late at night",
        story: "Let me tell you a story…",
        story_text: "Since our modest beginnings in 2005 with a little space in Iassy’s stylish Yorkville locale, TikiBistro‘s development has been enlivened with the energy to cook and serve solid, Exotic carefully selected delicious food from around the world. In contrast to other bistros, TikiBistro was made with the explicit expectation to appear as something else. We realize numerous individuals love exotic food, yet a large number of them loathe or are unconscious of the regularly unfortunate fixings that make run of the mill Indian nourishment taste so great. Our menu highlights things that utilization the sound and  fragrant flavors, however forgets the stuffing ghee, spread, oil, and overwhelming cream.",
        about_text: "We realize numerous individuals love exotic food, yet a large number of them loathe or are unconscious of the regularly unfortunate fixings that make run of the mill Indian nourishment taste so great. Our menu highlights things that utilization the sound and fragrant flavors, however forgets the stuffing ghee, spread, oil, and overwhelming cream.",
        contact_us: "Contact us",
        contact_us_desc: "Do you have questions, concerns or do you want to book a meal? Do not hesitate to contact us, by using the form or the contact information below.",
        send: 'Send',
        vezi_meniul: 'See the full menu',
        vezi_toate: 'View all',
        adauga_in_cos: 'Add to Cart',
        produse_in_cos: "products in the cart",
        adauga_ingrediente:"Add extra ingredients",
        priv_poli_text: "Here you can put any of your text that you would like to be displayed here. It can be any text that you think would be suitable. You can insert your already existing privacy policy or create a new one and put it here as well. It is entirely up to you and you can use any text that you think would be suitable for this app screen. .",
        one_product : "product",
        my_addresses : "My addresses",
        my_cards: "My cards",
        my_favorites: "My favorites",
        log_out: "Log out",
        items: "items",
        delivery_address: "Delivery address",
        where_to_deliver: "Where to deliver?",
        payment_method: "Payment method",
        select_payment_method:"Select payment method",
        phone_number : "Phone number",
        your_phone_number:"Your phone number?",
        delivery:"Delivery",
        confirm_order:"Confirm order",
        address_details:"Address details",
        complete:"Add address",
        complete_phone:"Add number phone",
        enter_your_phone_number:"Enter your phone number",
        eroare_login : "Login data is incorrect. Please check your username and password.",
        or : "or",
        order_sent: "Order sent!",
        mesaj_timp_livrare1: "Your order has been sent. In about ",
        mesaj_timp_livrare2: " minutes it should be at your door.",
        go_it: "Go it",
        plata_la_preluarea_comenzii : "Cash on delivery",
        plata_cu_cardul : "Pay with credit card or Apple Pay / Google Pay",
        nume_existent: "Email address already exists.",
        adrese_neadaugate: "You have not added any addresses yet.",
        adrese : "address",
        adauga_adresa_noua: "Add new address",
        save_address : "Save address",
        edit_address: "Save address",
        delete_address: "Delete address",
        comanda_nepreluata: "Order not taken",
        la_bucatarie: "The order is in the kitchen",
        add_new_card: "Add new card",
        no_favorites: "No favorites",
        fara_produs_favorit: "Seems like you haven’t save any of your favourite food yet",
        selecteaza_adresa: "Select the address",
        produs_adaugat_cos: "The product has been successfully added to the cart!",
        produs_adaugat_favorite: "The product has been added to the favorites list!",
        produs_sters_favorite: "The product has been removed from the favorites list!",
        search_food: "Search food..",
        search_result: "Search result",
        best_sellers_result: "Wanna try our best sellers?",
        autentificare_succes: "You have successfully logged in!",
        date_editate: "Account data successfully edited!",
        eroare_editare : "All fields are required!",
        text_logout: "You have logged out of your account!",
        livrare_mai_mica_1 : "You still need ",
        livrare_mai_mica_2 : "lei to have free delivery!",
        livrare_minima : "lei for minimum order.",
        livrare_gratuita: "Congratulations, you have free delivery!",
        vezi_bucataria: "See the kitchen",
        introdu_codul: "Enter the 4-digit code sent to you at ",
        introdu_telefonul: "Enter your phone number",
        next: "Next",
        cod_neprimit: "I haven't received a code",
        apply_voucher : "Apply",
        voucher_status_expired : "This code is not valid.",
        voucher_status_valid : "This code was applied with succesfull.",
        comanda_platita : "Order status",
        comanda_refuzata : "The order was declined",
        introduceti_adresa_noua : "Only street and number",
        detalii_adresa: "Building, Floor, Door, Notes",
        cod_retrimis: "The code has been successfully resent!",
        cod_netrimis:"Error sending code.",
        adresa_default: "This is our address!",
        no_orders: "No orders yet",
        no_orders_desc: "Try one of our awesome products and place your first order!",
        contact_trimis: "The message was successfully sent!",
        enter_email_addr: "Enter your email address",
        ridicare_personala: "Pickup at our location",
        validare_telefon : "Check phone",
        politica_de_confidentialitate: 'By clicking "Send", you confirm that you are familiar with, fully agree and accept the terms Consent to the processing of personal data',
        on : "on",
        introduceti_adresa_editata: "Enter address",
        mesaj_timp_livrare3: "Your order has been placed successfully. We are waiting for you at our location to pick up your order!",
        alerta_telefon_null : "Phone number successfully added!",
        verifica_phone : "Check the number",
    }
};

const i18n = new VueI18n({
    locale: 'ro', // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});

export default i18n;