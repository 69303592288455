<template>
  <div class="home">
    <section class="container_sectiune1" >
      <div class="containerg sectiune1">
        <div class="text_sectiune1">{{ $t('livrari_si_take_away') }}</div>
        <div class="text_sectiune1">{{ $t('fiecare_zi') }}</div>
        <div class="meniu_bucatarie">
          <a href="/meniu" class="vezi_meniu_a element_desktop vezi_meniu">{{ $t('vezi_tot_meniul') }}</a> 
          <!-- <a href="/bucatarie" class="vezi_meniu_a element_desktop vezi_meniu margin_vezi_bucatarie">{{ $t('vezi_bucataria') }}</a> -->
        </div>  
      </div>
    </section>
    <section class="sectiune_mobile containerg">
       <form action="/search">
        <div class="search_relative ">
          <input class="search_header_mobile" name='keywords' type="search" :placeholder="$t('search_food')" />
          <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'search' }" class="icon_search"/>
        </div>
       </form>
    </section>
    <section class="container_sectiune2 containerg">
         <div @click="showPrevCategorii" class="prev_button prev_next_button "><i class="ri-arrow-left-s-line "></i></div>
         <!-- <VueSlickCarousel v-bind="settings" ref="carouselCategorii" v-if="categorii.length"> -->
           <div class="carousel_categorii_home" v-if="categorii_slider.length">
             <div v-for="categorie in categorii_slider"  :key="categorie.id">
              <a v-if="categorie.products.length > 0"  class="box_carousel"  :href="'/meniu#produse-'+categorie.slug" >{{categorie.name}}</a>
             </div>
          </div>
        <!-- </VueSlickCarousel> -->
        <div @click="showNextCategorii" class="next_button prev_next_button"><i class="ri-arrow-right-s-line"></i></div>
    </section>
    <section class="container_sectiune3 containerg ">
        <div class="text_container padding_mobile_16" v-if="sliders.length > 3">
          <!-- {{ $t('special_offers') }} -->
          <div  @click="showNextSpec" class="next_button_preparate prev_next_button"><i class="ri-arrow-right-s-line"></i></div>
          <div  @click="showPrevSpec" class="prev_button_preparate prev_next_button"><i class="ri-arrow-left-s-line "></i></div>  
        </div>

        

        <div class="slick_general_preparate slick_element_desktop" v-if="!isMobile()">
            
            <VueSlickCarousel v-bind="settings_special" ref="carouselSpec" v-if="sliders.length">
              <div  v-for="slider in sliders" :key="slider.id" class="box_container box_container_slider_homepage">
                <a v-if="slider.slider_type == 'External'" :href="slider.link" v-on:click="sliderProduseHome(slider.link)"><img :src="slider.image[0].resizedpath"/></a>
                <div class="slider_homepage_internal" v-else-if="slider.slider_type == 'Internal'" @click="deschidereModalProdus(slider.product.id,$event)"><img :src="slider.image[0].resizedpath"/></div>
              </div>
            </VueSlickCarousel>
          </div>
          <div class="slick_element_mobile" v-if="isMobile()">
            <div  v-for="slider in sliders" :key="slider.id" class="box_container box_container_slider_homepage">
               <a v-if="slider.slider_type == 'External'" :href="slider.link" v-on:click="sliderProduseHome(slider.link)"><img :src="slider.image[0].resizedpath"/></a>
               <div class="slider_homepage_internal" v-else-if="slider.slider_type == 'Internal'" @click="deschidereModalProdus(slider.product.id,$event)"><img :src="slider.image[0].resizedpath"/></div>
            </div>
          </div>
    </section>


    <section class="container_sectiune5 containerg container_home_sliders">
        <div v-for="categorie in categorii" :key="categorie.id" :data-anchor="'prod'+categorie.slug" :class="'padding_text_container produse-'+categorie.slug" >
            <div class="text_container">
              {{categorie.name}}
              <a href="/meniu" class="element_mobile vezi_tot">{{ $t('vezi_toate') }}<i class="ri-arrow-right-s-line"></i></a>
            </div>
            
            <div class="slick_slide_cuisines slick_general_preparate slick_element_desktop" v-if="!isMobile()">
              <VueSlickCarousel v-bind="settings_carousel_homepage"  ref="carousel_home"  v-if="categorie.products.length > 0">
                <div :dataslug="categorie_best_sellers.additional.slug_ro" :dataidprod="categorie_best_sellers.id" class="box_produs box_container5 box_general" v-for="categorie_best_sellers in categorie.products" :key="categorie_best_sellers.id"  @click="deschidereModalProdus(categorie_best_sellers.id, $event)">
                  <div class="positionrelative">
                    <div v-if="$store.state.status_bucatarie == '2' && categorie_best_sellers.preparing_time == 'Normal'" class="produs_indisponibil">
                      Indisponibil momentan
                    </div>
                    <div v-if="categorie_best_sellers.main_image.length > 0">
                      <div v-for="imagine in categorie_best_sellers.main_image.slice(0,1)" :key="imagine.thumbnailpath">
                        <img :src="imagine.thumbnailpath"/>
                      </div>
                    </div>
                    <div v-else>
                      <img src='@/assets/images/poza_default1.jpg' />
                    </div>
                    <i class="ri-heart-fill heart_absolute favorit element_desktop" :datastatus="0" :dataid="categorie_best_sellers.id"   v-if="filter(categorie_best_sellers.id)"></i>
                    <i v-else class="ri-heart-line heart_absolute nefavorit element_desktop" :datastatus="1" :dataid="categorie_best_sellers.id"></i>
                    <div class="reducere_icon" v-if="categorie_best_sellers.discount > 0">
                        -{{categorie_best_sellers.discount}}%
                    </div>
                  </div>
                  <div class="titlu_box" v-if="$i18n.locale == 'ro'">
                      {{ categorie_best_sellers.additional.name_ro }}
                    </div>
                    <div class="titlu_box" v-else>
                      {{ categorie_best_sellers.additional.name_en }}
                    </div>
                    <div class="descriere_box" v-if="$i18n.locale == 'ro'">
                      {{categorie_best_sellers.additional.description_ro | strippedContent}}
                    </div>
                    <div class="descriere_box" v-else>
                      {{categorie_best_sellers.additional.description_en | strippedContent}}
                    </div>
                  <div class="pret_cumpara">
                    <div class="box_pret_container" v-if="categorie_best_sellers.discount > 0">
                        <div class="pret_box_taiat" >{{ categorie_best_sellers.price }} lei</div>
                        <div class="pret_box" >{{ categorie_best_sellers.price_final }} lei</div>
                        
                      </div>
                      <div class="box_pret_container" v-else>
                        <div class="pret_box" >{{ categorie_best_sellers.price }} lei</div>
                      </div>
                    <!-- <div class="cumpara_box" v-if="$store.state.status_bucatarie == '1' || ($store.state.status_bucatarie == '2' && categorie_best_sellers.preparing_time == 'Fast')" @click="adaugaInCosSingle(categorie_best_sellers.additional.slug_ro)">{{ $t('adauga_in_cos') }}</div> -->
                    <div class="cumpara_box"  v-if="$store.state.status_bucatarie == '1' || ($store.state.status_bucatarie == '2' && categorie_best_sellers.preparing_time == 'Fast')">{{ $t('adauga_in_cos') }}</div>
                  </div>
                </div>
              </VueSlickCarousel>
            </div>
            <div class="slick_element_mobile" v-if="isMobile()">     
              <div :dataslug="categorie_best_sellers.additional.slug_ro" :dataidprod="categorie_best_sellers.id" class="box_produs box_container5 box_general" v-for="categorie_best_sellers in categorie.products" :key="categorie_best_sellers.id" @click="deschidereModalProdus(categorie_best_sellers.id, $event)">
                  <div class="positionrelative">
                    <div v-if="$store.state.status_bucatarie == '2' && categorie_best_sellers.preparing_time == 'Normal'" class="produs_indisponibil">
                      Indisponibil momentan
                    </div>
                    <div v-if="categorie_best_sellers.main_image.length > 0">
                      <div v-for="imagine in categorie_best_sellers.main_image.slice(0,1)" :key="imagine.thumbnailpath">
                        <img :src="imagine.thumbnailpath"/>
                      </div>
                    </div>
                    <div v-else>
                      <img src='@/assets/images/poza_default1.jpg' />
                    </div>
                    <i class="ri-heart-fill heart_absolute favorit element_desktop" :datastatus="0" :dataid="categorie_best_sellers.id"   v-if="filter(categorie_best_sellers.id)"></i>
                    <i v-else class="ri-heart-line heart_absolute nefavorit element_desktop" :datastatus="1" :dataid="categorie_best_sellers.id"></i>
                    <div class="reducere_icon" v-if="categorie_best_sellers.discount > 0">
                        -{{categorie_best_sellers.discount}}%
                    </div>
                  </div>
                  <div class="titlu_box" v-if="$i18n.locale == 'ro'">
                      {{ categorie_best_sellers.additional.name_ro }}
                    </div>
                    <div class="titlu_box" v-else>
                      {{ categorie_best_sellers.additional.name_en }}
                    </div>
                    <div class="descriere_box" v-if="$i18n.locale == 'ro'">
                      {{categorie_best_sellers.additional.description_ro | strippedContent}}
                    </div>
                    <div class="descriere_box" v-else>
                      {{categorie_best_sellers.additional.description_en | strippedContent}}
                    </div>
                  <div class="pret_cumpara">
                    <div class="box_pret_container" v-if="categorie_best_sellers.discount > 0">
                        <div class="pret_box_taiat" >{{ categorie_best_sellers.price }} lei</div>
                        <div class="pret_box" >{{ categorie_best_sellers.price_final }} lei</div>
                        
                      </div>
                      <div class="box_pret_container" v-else>
                        <div class="pret_box" >{{ categorie_best_sellers.price }} lei</div>
                      </div>
                    <!-- <div class="cumpara_box" v-if="$store.state.status_bucatarie == '1' || ($store.state.status_bucatarie == '2' && categorie_best_sellers.preparing_time == 'Fast')" @click="adaugaInCosSingle(categorie_best_sellers.additional.slug_ro)">{{ $t('adauga_in_cos') }}</div> -->
                    <div class="cumpara_box"  v-if="$store.state.status_bucatarie == '1' || ($store.state.status_bucatarie == '2' && categorie_best_sellers.preparing_time == 'Fast')"><i class="ri-shopping-cart-2-line"></i></div>
                  </div>
                </div>

            </div>
        </div>
    </section>
    
    
    <section class="sectiune_fixa_mobil">
        <a href="/meniu" class="no_decoration meniu_button"><div class="vezi_meniul_buton">{{ $t('vezi_meniul') }}</div></a>
        <a href="tel:0747247876" class="no_decoration"><div class="phone_mobile"><i class="ri-phone-line"></i></div></a>
    </section>
  </div>
</template>



<script>
  import VueSlickCarousel from 'vue-slick-carousel'
  import 'vue-slick-carousel/dist/vue-slick-carousel.css'
  // optional style for arrows & dots
  import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
  import axios from 'axios';
  import $ from 'jquery'

 
  export default {
    name: 'MyComponent',
    components: { VueSlickCarousel   },
    methods: {
      isMobile() {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return true
        } else {
          return false
        }
      },
      replace_nume_bucatarie(nume) {
        return nume.replace(" ", "-"); 
      },
      showNextBest() {
        //this.$refs.carousel_home.next() 
      },
      showPrevBest() {
        this.$refs.carousel_home.prev() 
      },
      showNextSpec() {
        this.$refs.carouselSpec.next() 
      },
      showPrevSpec() {
        this.$refs.carouselSpec.prev() 
      },
      showNextCategorii() {
         $('.carousel_categorii_home').animate( { scrollLeft: '+=200' }, 1000);
      },
      showPrevCategorii() {
        $('.carousel_categorii_home').animate( { scrollLeft: '-=200' }, 1000);
      },
      filter(id_produs){
        return this.$root.$refs.Header.filter(id_produs);
      },
      deschidereModalProdus(id,$event) {
        this.produse_extra = []
        this.$root.$refs.Header.afisare_modal_produs(id,$event);
      },
      sliderProduseHome: function(link) {
        console.log(link);
        window.location.href = link;
      },
      adaugaInCosSingle(id) {
        this.$root.$refs.Header.adaugaProdusFavoritInCos(id);
      },
    },
    data() {
      return {
        settings_special: {
          arrows: false,
          dots: false,
          slidesToShow: 3,
          responsive: [
            {
              "breakpoint": 1024,
              "settings": {
                "slidesToShow": 2,
                "slidesToScroll": 2,
                "infinite": true,
              }
            },
            {
              "breakpoint": 480,
              "settings": {
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "centerMode": true,
                "centerPadding": "40px",
              }
            }
          ]
        },
         settings_carousel_homepage: {
          arrows: true,
          dots: false,
          slidesToShow: 4,
          nextArrow: '.next_home',
          prevArrow: '.prev_home',
          responsive: [
            {
              "breakpoint": 1024,
              "settings": {
                "slidesToShow": 2,
                "slidesToScroll": 2,
                "infinite": true,
                "dots": true
              }
            },
            {
              "breakpoint": 480,
              "settings": {
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "centerMode": true,
                "centerPadding": "40px",
              }
            }
          ]
        },
         categorii : [], 
         categorii_slider : [],
         categorii_best_sellers : [],
         categorii_oferte_speciale : [],
         sliders : [],
         bauturi_racoritoare : [],
         slug_bauturi_racoritoare : '',
         bauturi_alcoolice : [],
         slug_bauturi_alcoolice : '',
         cuisine : [],
         nume_produs : "",
        descriere_produs : "",
        imagine_produs : "",
        external_id: "",
        pret : "",
        alergeni_ro:"",
        alergeni_en:"",
        ingrediente_extra : [],
        produse_favorite : [],
        id_produs : '', 
        count: 1,
        produse_extra: [],
        url_delivery : 0,
      } 
    },
     mounted () {
       

        $('.search_header_mobile').keypress(function(event){
            var keycode = (event.keyCode ? event.keyCode : event.which);
            if(keycode == '13'){
                var search_key = $('.search_header_mobile').val();
                window.location.href = '/search?keywords='+search_key;
            }
            event.stopPropagation();
        });
         $('.search_header_mobile').focus( function() {
           if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
              $('.sectiune_fixa_mobil').hide();
           }
          
          });

          $('.search_header_mobile').blur( function() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
              $('.sectiune_fixa_mobil').fadeIn(100);
            }
          });
     },
     beforeMount() {
      if(window.location.hostname == 'test.tikibistro.ro' || window.location.hostname == '192.168.1.80')
        this.url_delivery = 1;
      

       axios.get('https://xpanel.tikibistro.ro/api/cuisine?t='+Number(new Date()),{ 
          withCredentials: false,
        })
        .then(response => {
            
            this.cuisine = response.data;
        })
       

      axios.get('https://xpanel.tikibistro.ro/api/slider?t='+Number(new Date()),{ 
        withCredentials: false,
      })
      .then(response => {
          var slider_parsat = response.data.data.sort((a,b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
          this.sliders = slider_parsat;
      })

      axios.get('https://xpanel.tikibistro.ro/api/categories?t='+Number(new Date()),{ 
          withCredentials: false,
          params: { location: this.$store.state.location_shop }
        })
        .then(response => {
            var array_categorii = [];
            var array_categorii_slider = [];
            response.data.data.forEach(category => { 
              if(((category.products.length > 0 && category.slug != 'test' && this.url_delivery == 0) || (category.products.length > 0 && this.url_delivery == 1)) && category.homepage == 1)
                  array_categorii.push(category);

              if(category.products.length > 0 && category.slug != 'test')
                  array_categorii_slider.push(category);
            })
            this.categorii = array_categorii;
            this.categorii_slider = array_categorii_slider;

        })
    },
    filters: {
        strippedContent: function(string) {
              return string.replace(/<\/?[^>]+>/ig, ""); 
        }
    }
  } 
</script>


