<template>
  <div id="app">
     
    <Header/>
    <router-view/>
    <Footer/>
  </div>
</template>

<style>
  @import './assets/css/style.css';
  @import './assets/css/style_responsive.css';
  
</style>
<style>
@font-face {
    font-family: 'SF Pro Display';
    src: url('./fonts/SFProDisplay-Medium.woff2') format('woff2'),
        url('./fonts/SFProDisplay-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./fonts/SFProDisplay-Ultralight.woff2') format('woff2'),
        url('./fonts/SFProDisplay-Ultralight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./fonts/SFProDisplay-Regular.woff2') format('woff2'),
        url('./fonts/SFProDisplay-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./fonts/SFProDisplay-LightItalic.woff2') format('woff2'),
        url('./fonts/SFProDisplay-LightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./fonts/SFProDisplay-BlackItalic.woff2') format('woff2'),
        url('./fonts/SFProDisplay-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./fonts/SFProDisplay-Light.woff2') format('woff2'),
        url('./fonts/SFProDisplay-Light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./fonts/SFProDisplay-HeavyItalic.woff2') format('woff2'),
        url('./fonts/SFProDisplay-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./fonts/SFProDisplay-Thin.woff2') format('woff2'),
        url('./fonts/SFProDisplay-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./fonts/SFProDisplay-BoldItalic.woff2') format('woff2'),
        url('./fonts/SFProDisplay-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./fonts/SFProDisplay-MediumItalic.woff2') format('woff2'),
        url('./fonts/SFProDisplay-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./fonts/SFProDisplay-Black.woff2') format('woff2'),
        url('./fonts/SFProDisplay-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./fonts/SFProDisplay-Semibold.woff2') format('woff2'),
        url('./fonts/SFProDisplay-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('./fonts/SFProText-BoldItalic.woff2') format('woff2'),
        url('./fonts/SFProText-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap; 
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('./fonts/SFProText-Bold.woff2') format('woff2'),
        url('./fonts/SFProText-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./fonts/SFProDisplay-RegularItalic.woff2') format('woff2'),
        url('./fonts/SFProDisplay-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./fonts/SFProDisplay-Bold.woff2') format('woff2'),
        url('./fonts/SFProDisplay-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./fonts/SFProDisplay-ThinItalic.woff2') format('woff2'),
        url('./fonts/SFProDisplay-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./fonts/SFProDisplay-SemiboldItalic.woff2') format('woff2'),
        url('./fonts/SFProDisplay-SemiboldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./fonts/SFProDisplay-Heavy.woff2') format('woff2'),
        url('./fonts/SFProDisplay-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('./fonts/SFProDisplay-UltralightItalic.woff2') format('woff2'),
        url('./fonts/SFProDisplay-UltralightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('./fonts/SFProText-Light.woff2') format('woff2'),
        url('./fonts/SFProText-Light.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('./fonts/SFProText-Heavy.woff2') format('woff2'),
        url('./fonts/SFProText-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('./fonts/SFProText-Medium.woff2') format('woff2'),
        url('./fonts/SFProText-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('./fonts/SFProText-HeavyItalic.woff2') format('woff2'),
        url('./fonts/SFProText-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('./fonts/SFProText-LightItalic.woff2') format('woff2'),
        url('./fonts/SFProText-LightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('./fonts/SFProText-RegularItalic.woff2') format('woff2'),
        url('./fonts/SFProText-RegularItalic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('./fonts/SFProText-MediumItalic.woff2') format('woff2'),
        url('./fonts/SFProText-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('./fonts/SFProText-Regular.woff2') format('woff2'),
        url('./fonts/SFProText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SF Pro Text';
    src: url('./fonts/SFProText-Semibold.woff2') format('woff2'),
        url('./fonts/SFProText-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

</style>

<script>
import Vue from 'vue'
  import i18n from '@/plugins/i18n';
  import Header from './components/common/Header'  
  import Footer from './components/common/Footer'

  import VueMeta from 'vue-meta'
  Vue.use(VueMeta)
 

  export default {
    metaInfo() {
        return { 
            title: "Tiki Bistro | Livrări și take-away | În fiecare zi",
            meta: [
                { name: 'description', content:  'Tiki Bistro, un mic restaurant exotic, însuflețit cu energia de a găti și a servi mâncăruri delicioase, exotice, selectate cu grijă din întreaga lume.'},
                { property: 'og:title', content: "Tiki Bistro | Livrări și take-away | În fiecare zi"},
                { property: 'og:description', content: 'Tiki Bistro, un mic restaurant exotic, însuflețit cu energia de a găti și a servi mâncăruri delicioase, exotice, selectate cu grijă din întreaga lume.'},
                { property: 'og:site_name', content: 'Tiki Bistro'},
                {property: 'og:type', content: 'website'},    
                {name: 'robots', content: 'index,follow'} 
            ]
        }
    },
    components: {
      Footer,
      Header
    },
    data() {
        return {
            languages: [
                { flag: '@/assets/images/icon-romanian-flag.png', language: 'ro', title: 'Romana' },
                { flag: '@/assets/images/Group 2225.png', language: 'en', title: 'English' }
            ],
        };
    },
    methods: {
        changeLocale(locale) {
            i18n.locale = locale;
        }
    }
   
    // ...
  }
</script>