import axios from "axios";
import $ from 'jquery'

const state = {
  user: null, 
};

const first_name_logged = {
  first_name_logged : '',
  last_name_logged : '',
  eroare_generala_bool : false,
  eroare_generala : '' 
}


const getters = {
  isAuthenticated: (state) => !!state.user,
  StateUser:  (state) => state.user,
};

var optionAxios = { 
    withCredentials: false
}

const actions = {
  async Register({dispatch}, form_register) {
    await axios.post('user/register', form_register, optionAxios)
    let UserForm = new FormData()
    UserForm.append('email', form_register.email)
    UserForm.append('password', form_register.password)
    UserForm.append('first_name', form_register.first_name)
    UserForm.append('last_name', form_register.last_name)
    UserForm.append('phone', form_register.phone)
    await dispatch('LogIn', UserForm)
  },
  async LogIn({commit}, user) {
    await axios.post("auth/login", user, optionAxios).then((response) => {
      this.first_name_logged = response.data.data[0]['user'].first_name;
      this.last_name_logged = response.data.data[0]['user'].last_name;
      localStorage.setItem('token', response.data.data[0].token);
      localStorage.setItem('date_login',new Date());
      $('.inapoi_modal_start').click();
      $('.sweet-action-close').click();
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        $('.modal_autentificare_facebook').animate({ "right": "0px"}, "slow");
      } else{
          $('.modal_autentificare_facebook').animate({ "right": "20px"}, "slow");
      }
    setInterval(function(){
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        $('.modal_autentificare_facebook').animate({"right": "-110vw"}, "slow");
      } else{
        $('.modal_autentificare_facebook').animate({"right": "-100vw"}, "slow");
      }
    }, 5000);
      this.eroare_generala_bool = false;
      this.eroare_generala = '';
    }).catch((error) => {
      console.log(error);
      this.eroare_generala_bool = true;
      this.eroare_generala = 'Utilizatorul sau parola sunt incorecte.';
    });
    if(this.eroare_generala_bool == false)
      await commit("setUser", user.get("email"));
  },

  async LogOut({ commit }) {
    let user = null;
    localStorage.removeItem('token');
    commit("logout", user);
  
  },
};

const mutations = {
  setUser(state, email) {
    state.user = email;
  },
  logout(state, user) { 
    console.log(state);
    console.log(user);
    localStorage.removeItem('token');
    if(!$('.menu__blackout:visible').length == 0)
    {
        $('.close_menu').click();
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
              $('.modal_logout').animate({ "right": "0px"}, "slow");
            } else{
                $('.modal_logout').animate({ "right": "20px"}, "slow");
            }
          setInterval(function(){
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
              $('.modal_logout').animate({"right": "-110vw"}, "slow");
            } else{
              $('.modal_logout').animate({"right": "-100vw"}, "slow");
            }
      }, 5000); 
    }
    state.user = user;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  first_name_logged,
};